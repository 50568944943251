import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { device } from "../utils/mixins"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"

import {
  PageSectionTitle,
  I2BronzeText,
  BodyText,
} from "../components/textComponents"

import { PageImageButton } from "../components/interactiveComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

function PrivacyPolicy() {
  var data = useStaticQuery(graphql`
    query {
      privacyTopImg: file(
        relativePath: {
          eq: "i2_Optimization_Drilling_Production_Completions_Canada_United_States.jpeg"
        }
      ) {
        ...fluidImageSVG
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.privacyTopImg.sharp.fluid}
          alt="i2 Optimization - Optimizing Drilling, Production, and Completions across Canada and The United States."
        />
        <PageSectionTitle>
          Privacy <I2BronzeText>Policy</I2BronzeText>
        </PageSectionTitle>
        <PageImageButton to="/contact-us/">Contact Us</PageImageButton>
      </PageTopFullSizeImgCont>

      <SectionContentContainer>
        <BodyText>
          When you visit the i2 Optimization website, certain information is
          collected that is used to improve our website and to respond to your
          interests and needs. This information includes your computer's IP
          address, the type of browser that you use and the web pages or files
          that you access. i2 Optimization may also collect information from you
          when you make inquiries about i2 Optimization. i2 Optimization uses
          the information that it collects to contact you in response to your
          inquiry. Under no circumstances will your personal information be
          disclosed to any third party unless you expressly consent or if we are
          required to do so by law.
        </BodyText>

        <BodyText>
          Some of our web pages may utilize "cookies" which constitute small
          data files sent to your web browser and stored on your computer. A
          cookie allows our server to recognize you when you visit our web pages
          in the future so as to make your next visit more efficient and to
          allow us to track site usage for the purpose of making content
          improvements. Most web browsers will notify you when a cookie is to be
          sent to you and enable you to decline to accept the cookie. You are
          not required to accept a cookie in order to visit any of our web
          pages.
        </BodyText>

        <BodyText>
          The i2 Optimization website use appropriate technology and procedural
          measures to provide protection for the information that we collect.
        </BodyText>

        <BodyText>
          By using the i2 Optimization website, you consent to the collection
          and use of your personal information as described above. If i2
          Optimization changes this Privacy Policy in the future, the changes
          will be posted on this website so you can always know what information
          we collect and how we use it. Any future access to this website
          subsequent to the posting of such changes will be deemed to be an
          acceptance of, and agreement to, our Privacy Policy as amended.
        </BodyText>
      </SectionContentContainer>
    </Layout>
  )
}

export default PrivacyPolicy
